// dev
// export const config =  {
//     baseUrl: 'https://pbapinorway.dev.boo2.nl',
//     portal_url: 'https://volvonorway.dev.boo2.nl',
// }

//staging
export const config =  {
    baseUrl: 'https://pbapinorway.stg.boo2.nl/',
    portal_url: 'https://volvonorway.stg.boo2.nl/',
}